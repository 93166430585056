import Layout from '@/layout/layout'

const classificationRouter = {
  path: '/classification',
  component: Layout,
  redirect: 'noRedirect',
  name: '回收分类管理',
  children: [
    {
      path: 'add',
      component: () => import('@/views/classification/add/index'),
      name: '添加分类',
      meta: { title: '添加分类', noCache: true }
    },
    {
      path: 'ClassList',
      component: () => import('@/views/classification/ClassList/index'),
      name: '分类列表',
      meta: { title: '分类列表', noCache: true }
    }
  ]
}

export default classificationRouter