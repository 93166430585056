import Layout from '@/layout/layout'

const onlineRecyclingRouter = {
    path: '/IndependentManagement',
    component: Layout,
    redirect: 'noRedirect',
    name: '线上回收',
    children: [
        {
            path: 'IndepManagement',
            component: () => import('@/views/IndependentManagement/IndepManagement/index'),
            name: '独立部署管理',
            meta: { title: '独立部署管理', noCache: true }
        },
        {
            path: 'Resources',
            component: () => import('@/views/IndependentManagement/Resources/index'),
            name: '独立部署资源配置',
            meta: { title: '独立部署资源配置', noCache: true }
        },
        {
            path: 'WeChatPublicConfig',
            component: () => import('@/views/IndependentManagement/WeChatPublicConfig/index'),
            name: '公众号配置',
            meta: { title: '公众号配置', noCache: true }
        },
    ]
}

export default onlineRecyclingRouter
