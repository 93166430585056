<template>
	<div>
		<div class="el-img-upload-group">
			<ElImgUpload
				v-for="(i,index) in imgList"
				:key="index"
				:exist-image="i"
				:fileSize="fileSize"
				:isBigImg="isBigImg"
				@handleDelete="handleDelete(index)"
				@handleChange="handleChange($event, index)"
			/>
			<div v-if="imgList.length <= length-1">
				<el-upload
					:action="uploadUrl"
					:headers="headers"
					:show-file-list="false"
					:on-success="handleAvatarSuccess"
					:before-upload="beforeAvatarUpload"
					class="avatar-uploader"
				>
					<i class="el-icon-plus avatar-uploader-icon" />
				</el-upload>
			</div>
		</div>
		<div class="el-upload__tip">{{placeTxt}}</div>
	</div>
</template>

<script>
import ElImgUpload from './upload-element'
import environments from '@/config/url'
import { encryptByDES} from '@/utils/3DES'

const uploadUrl = environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl
export default {
	name: 'GlobalUploadGroup',
	components: {
		ElImgUpload
	},
	props: {
		existImgList: {
			type: Array,
			default () {
				return []
			}
		},
		placeTxt:{
			type: String,
			default: ''
		},
		length: {
			type: Number,
			default: 5
		},
		fileSize: {
			type: Number,
			default: 2
		},
		isBigImg: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			uploadUrl,
			imgList: [],
			headers:{
				'ACCESS_TOKEN': localStorage.getItem('token'),
				'channel':'platformPc',
				'TOKEN':""
			},
		}
	},
	watch: {
		existImgList () {
			this.imgList = !this.existImgList ? [] : this.existImgList
			console.log(this.imgList)
			this.$emit('handleChange', this.imgList)
		}
	},
	created () {
		this.imgList = !this.existImgList ? [] : this.existImgList
		console.log(this.imgList)
	},
	methods: {
		handleDelete (index) {
			this.imgList.splice(index, 1)
		},
		handleChange (e, index) {
			console.log('123')
			this.imgList[index] = e
		},
		handleAvatarSuccess (res) {
			console.log(res, this.imgList, this.length)
			if (this.imgList.length <= this.length - 1) {
				this.imgList.push(res.fileUrl)
			} else {
				// 超出最后一张替换
				// this.imgList.splice(this.length - 1, 1, res.fileUrl)
				// 提示
				this.$message.error('超出上传限制')
			}
		},
		beforeAvatarUpload (file) {
			this.headers.TOKEN = encryptByDES(encryptByDES((new Date().getTime()).toString(),'63e42c2444e94c1ebca21d30d2aa39a5'),localStorage.getItem('token') || '')
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
			const isLt2M = file.size / 1024 / 1024 < this.fileSize
			if (!isJPG) {
				this.$message.error('上传头像图片只能是 JPG或者PNG 格式!')
			}
			if (!isLt2M) {
				this.$message.error(`上传图片大小不能超过 ${this.fileSize}MB!`)
			}
			return isJPG && isLt2M
		},
	}
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.el-img-upload-group{
	display: flex;
	flex-wrap: wrap;
	>div{
		margin-right: 20px;
	}
}
</style>
<style lang="scss">
@import "src/assets/scss/element/theme-color";
.el-img-upload-group{
	.el-upload {
		width: 120px;
		height: 120px;
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		.el-icon-plus{
			font-size: 30px;
			color: #999999;
		}
		.el-icon-circle-close{
			position: absolute;
			color: #999999;
			top: 2px;
			right: 2px;
			z-index: 100;
			font-size: 15px;
			opacity: 0.5;
			transition: opacity 0.2s;
			&:hover{
				opacity: 0.8;
				color: #ef032a;
			}
		}
	}
}
.el-upload__tip{
    color: $--color-danger;
  }
</style>
