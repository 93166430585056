import Layout from '@/layout/layout'

const classificationRouter = {
    path: '/marriedDeal',
    component: Layout,
    redirect: 'noRedirect',
    name: '撮合交易',
    children: [
        {
            path: 'matchmManage',
            component: () => import('@/views/marriedDeal/matchmManage/index'),
            name: '撮合管理',
            meta: { title: '撮合管理', noCache: true }
        },
        {
            path: 'matchBusiness',
            component: () => import('@/views/marriedDeal/matchBusiness/index'),
            name: '撮合交易商家',
            meta: { title: '撮合交易商家', noCache: true }
        },
        {
            path: 'matchBill',
            component: () => import('@/views/marriedDeal/matchBill/index'),
            name: '撮合交易账单',
            meta: { title: '撮合交易账单', noCache: true }
        }
    ]
}

export default classificationRouter