import Layout from '@/layout/layout'

const SystemOfferRouter = {
  path: '/SystemOffer',
  component: Layout,
  redirect: 'noRedirect',
  name: '系统报价回收',
  children: [
    {
      path: 'list',
      component: () => import('@/views/SystemOffer/list/index'),
      name: '商家列表',
      meta: { title: '商家列表', noCache: true }
    },
    {
      path: 'add',
      component: () => import('@/views/SystemOffer/add/index'),
      name: '添加商家',
      meta: { title: '添加商家', noCache: true }
    },
  ]
}

export default SystemOfferRouter
