<template>
  <section id="pageTabBar" class="page-tab-bar">
    <span class="button" style="margin-left: 14px;margin-right: 4px;" @click="handleScrollLeft">
      <img src="./img/htzy_ico_xz.png" />
    </span>
    <div ref="barContainer" class="bar-container">
      <router-link
        v-for="(item, index) in visitedViews"
        :key="index"
        :to="item.pathUrl"
        :class="['tags-view-item',{'active': isActive(item)}]"
      >
        {{item.title}}
        <i
          v-if="item.path !== '/dashboard'"
          class="el-icon-close"
          @click.prevent.stop="closeSelectedTag(item)"
        />
      </router-link>
    </div>
    <span class="button" style="margin-left: 4px;" @click="handleScrollRight">
      <img src="./img/htzy_ico_xy.png" />
    </span>
    <span class="button" v-if="false" style="margin-left: 4px;overflow: hidden" @click="isHide=!isHide">
      <img
        src="./img/htzy_ico_sq.png"
        style="transition: all 0.3s;"
      />
    </span>
    <div class="bar-console" v-show="false">
      <span @click="closeCurrentPage">关闭当前标签页</span>
      <span @click="closeOtherPages">关闭其他标签页</span>
      <span @click="closeAllPages">关闭全部标签页</span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'page-tab-bar',
  //   inject: ['cName'],
  computed: {
    visitedViews() {
      // console.log('当前tabsData', this.$store.state.tagsView.visitedViews)
      return this.$store.state.tagsView.visitedViews
    },
  },
  watch:{
    $route(){
      // console.log('TabBar监听路由变化', to, from);
      this.addTags()
    }
  },
  data() {
    return {
      offset: 70,
      isHide: false,
    }
  },
  mounted() {
    this.addTags()
    this.$refs.barContainer.scrollLeft = window.localStorage.getItem(
      'pageTabsBar'
    )
      ? window.localStorage.getItem('pageTabsBar')
      : 0
  },
  methods: {
    isActive(route) {
      return route.path === this.$route.path
    },
    addTags() {
      const { path } = this.$route
      this.$store.commit('tagsView/SET_TabBar_NAME', this.$route)
      if (path) {
        this.$store.dispatch('tagsView/addView', this.$route)
      }
      return false
    },
    closeSelectedTag(view) {
      this.$store
        .dispatch('tagsView/delVisitedView', view)
        .then((visitedViews) => {
          if (this.isActive(view)) {
            this.toLastView(visitedViews, view)
          }
          if (view.meta.name) {
            this.$store.commit('DEL_KEEPALIVE', view.meta.name)
          }
        })
    },
    toLastView(visitedViews) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.pathUrl)
      } else {
        this.$router.replace('/')
      }
    },
    handleScrollLeft() {
      let offsetVal =
        this.$refs.barContainer.scrollLeft - this.offset > 0
          ? this.$refs.barContainer.scrollLeft - this.offset
          : 0

      let timer = setInterval(() => {
        this.$refs.barContainer.scrollLeft -= 2
        if (this.$refs.barContainer.scrollLeft <= offsetVal) {
          window.localStorage.setItem(
            'pageTabsBar',
            this.$refs.barContainer.scrollLeft
          )
          clearInterval(timer)
        }
      }, 5)
    },
    handleScrollRight() {
      let offsetVal = this.$refs.barContainer.scrollLeft + this.offset
      let maxVal =
        this.$refs.barContainer.scrollWidth -
        this.$refs.barContainer.clientWidth
      let timer = setInterval(() => {
        this.$refs.barContainer.scrollLeft += 2
        if (
          this.$refs.barContainer.scrollLeft >= offsetVal ||
          this.$refs.barContainer.scrollLeft >= maxVal
        ) {
          window.localStorage.setItem(
            'pageTabsBar',
            this.$refs.barContainer.scrollLeft
          )
          clearInterval(timer)
        }
      }, 5)
    },
    closeCurrentPage() {
      console.log('关闭当前标签页')
      this.visitedViews.forEach((item) => {
        if (item.path !== '/dashboard' && item.path === this.$route.path) {
          this.closeSelectedTag(item)
        }
      })
      this.isHide = false
    },
    closeOtherPages() {
      console.log('关闭其他标签页')
      this.visitedViews.forEach((item) => {
        if (item.path === this.$route.path) {
          this.$store.dispatch('tagsView/delOtherView', item)
        }
      })
      this.isHide = false
    },
    closeAllPages() {
      console.log('关闭全部标签页')
      this.$store.dispatch('tagsView/delAllView')
      this.$router.push('/dashboard')
      this.isHide = false
    },
  },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.page-tab-bar {
  position: relative;
  height: 40px;
  //background-color: #d4d9f8;
  display: flex;
  align-items: center;
  z-index: 999;
  .bar-container {
    flex: 1;
    white-space: nowrap;
    overflow-x: scroll;
    /*滚动条样式设置*/
    &::-webkit-scrollbar {
      width: 0.2em;
      height: 0.2em;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 0.5em;
      -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    }
    /*滑块*/
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 0.5em;
      -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    }
    /*滑块轨道*/
    &::-webkit-scrollbar-track {
      border-radius: 0.5em;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }
  }
  .tags-view-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 30px;
    color: #777777;
    padding: 0 8px;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 3px;
    text-decoration: none;
    height: 30px;
    background: #E5F3FE;
    border-radius: 6px;
    &:first-of-type {
    }
    &:last-of-type {
      margin-right: 4px;
    }
    &.active {
      color: #333333;
      background: #DCECFD;
    }
    .el-icon-close {
      width: 16px;
      height: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
  .button {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 3px;
    cursor: pointer;
    > img {
      vertical-align: middle;
      width: 30px;
    }
  }
  .bar-console {
    position: absolute;
    right: 0;
    bottom: -120px;
    width: 136px;
    height: 110px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 10px 0px rgba(92, 92, 92, 0.15);
    opacity: 0.9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    > span {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(102, 102, 102, 1);
      &:hover {
        background-color: rgba(#d4d9f8, 0.5);
      }
    }
  }
}
</style>
