import Layout from '@/layout/layout'

const classificationRouter = {
    path: '/qrcode',
    component: Layout,
    redirect: 'noRedirect',
    name: '二维码管理',
    children: [
        {
            path: 'CreatDistribute',
            component: () => import('@/views/qrcode/CreatDistribute/index'),
            name: '二维码生成&分配',
            meta: { title: '二维码生成&分配', noCache: true }
        },
        {
            path: 'MerchantsQrcode',
            component: () => import('@/views/qrcode/MerchantsQrcode/index'),
            name: '门店商家二维码',
            meta: { title: '门店商家二维码', noCache: true }
        },
        {
            path: 'QrcodeSearch',
            component: () => import('@/views/qrcode/QrcodeSearch/index'),
            name: '二维码查询',
            meta: { title: '二维码查询', noCache: true }
        },
        {
            path: 'QrcodeDelivery',
            component: () => import('@/views/qrcode/QrcodeDelivery/index'),
            name: '二维码发货',
            meta: { title: '二维码发货', noCache: true }
        },
    ]
}

export default classificationRouter