<template>
  <el-dialog :close-on-click-modal="false" :width="`${width || 628}px`" :title="title" :visible="show" @close="onClose" :top="top">
    <slot></slot>
    <span slot="footer" class="dialog-footer">
        <el-button @click="Cancel">取 消</el-button>
        <el-button type="primary" :loading="sureLoading" @click="Save">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'GlobalDialog',
  props: {
    show: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    width: {
      type: Number,
    },
    top: {
      type: String,
      default: '15%'
    },
    sureLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    },
    Cancel () {
      this.$emit('resetPopupData')
    },
    Save () {
      this.$emit('submitPopupData')
    },
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
</style>
