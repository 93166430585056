import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/layout'

Vue.use(Router)

const fucFiles = require.context('./modules', false, /\.js$/)
const routerList = []
fucFiles.keys().forEach(key => {
    routerList.push(fucFiles(key).default || fucFiles(key))
})


export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/redirect',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '/redirect/:path*',
                    component: () => import('@/views/redirect/index')
                }
            ]
        },
        {
            path: '/',
            name: 'layout',
            meta: {title: 'Home', noCache: true, affix: true},
            component: Layout,
            redirect: '/dashboard'
        },
        {
            path: '/login',
            name: 'Login',
            meta: {title: 'Login', noCache: true, affix: true},
            component: () => import('@/views/login/login'),
        },
        ...routerList
    ]
})
