import Layout from '@/layout/layout'

const newMarkupRouter = {
  path: '/newMarkup',
  component: Layout,
  redirect: 'noRedirect',
  name: '换新加价活动',
  children: [
    {
      path: 'campaignPoster',
      component: () => import('@/views/newMarkup/campaignPoster/index'),
      name: ' 活动海报',
      meta: { title: ' 活动海报', noCache: true }
    },
    {
      path: 'merchantNewMarkup',
      component: () => import('@/views/newMarkup/merchantNewMarkup/index'),
      name: '商户换新活动',
      meta: { title: '商户换新活动', noCache: true }
    },
    {
      path: 'renewOrder',
      component: () => import('@/views/newMarkup/renewOrder/index'),
      name: '换新订单',
      meta: { title: '换新订单', noCache: true, fixed: true }
    }
  ]
}

export default newMarkupRouter
