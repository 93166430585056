// 矩阵
import Layout from "@/layout/layout.vue";

const matrix = {
    // 加载路径
    path:"/matrix",
    // 加载组件
    component:Layout,
    // 重定向路径
    redirect:"noRedirect",
    // 路由名称
    name:"矩阵管理",
    children:[
        {
            path:"config",
            name:"矩阵管理",
            component: ()=>import("@/views/matrix/config/index.vue"),
            meta:{title:"矩阵管理",noCache:true}
        }
    ]
}

export default matrix;
