import Layout from '@/layout/layout'

const logisticsManageRouter = {
    path: '/logisticsManage',
    component: Layout,
    redirect: 'noRedirect',
    name: '物流管理',
    children: [
        {
            path: 'apply',
            component: () => import('@/views/logisticsManage/apply/index'),
            name: '物流申请',
            meta: { title: '物流申请', noCache: true }
        },
        {
            path: 'order',
            component: () => import('@/views/logisticsManage/order/index'),
            name: '运单监控',
            meta: { title: '运单监控', noCache: true }
        }
    ]
}

export default logisticsManageRouter