<template>
  <div class="el-img-upload">
    <el-upload
        :action="uploadUrl"
        :headers="headers"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        class="avatar-uploader"
    >
      <div v-if="imageUrl">
        <img v-if="isBigImg" :src="imageUrl" @click.stop="seeBigImg(imageUrl)" class="avatar">
        <img v-else :src="imageUrl" class="avatar">
      </div>
      <i v-else class="el-icon-plus avatar-uploader-icon" />
      <i v-if="imageUrl" class="el-icon-circle-close" @click.stop="handleDelete" />
      <div slot="tip" class="el-upload__tip">
        {{ placeTxt }}
      </div>
    </el-upload>
    <el-image-viewer
        :zIndex="99999"
        v-if="showViewer"
        :on-close="
				() => {
					imgList = []
					showViewer = false;
				}
			"
        :url-list="imgList"
    />
  </div>
</template>

<script>
import environments from '@/config/url'
import { encryptByDES} from '@/utils/3DES'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
const uploadUrl = environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl
export default {
  name: 'GlobalUpload',
  props: {
    existImage: {
      type: String,
      default: ''
    },
    placeTxt: {
      type: String,
      default: ''
    },
    fileSize: {
      type: Number,
      default: 2
    },
    isBigImg: {
      type: Boolean,
      default: false
    }
  },
  components:{ElImageViewer},
  data() {
    return {
      uploadUrl,
      imageUrl: '',
      headers:{
        'ACCESS_TOKEN': localStorage.getItem('token'),
        'channel':'platformPc',
        'TOKEN': ''
      },
      showViewer: false,
      imgList: []
    }
  },
  watch: {
    existImage(val) {
      console.log(val);
      this.imageUrl = val
    }
  },
  created() {
    console.log(this.existImage);
    this.imageUrl = this.existImage
  },
  methods: {
    handleAvatarSuccess(res) {
      console.log(res)
      this.imageUrl = res.fileUrl
      this.$emit('handleChange', this.imageUrl)
    },
    beforeAvatarUpload(file) {
      console.log(file.type);
      this.headers.TOKEN = encryptByDES(encryptByDES((new Date().getTime()).toString(),'63e42c2444e94c1ebca21d30d2aa39a5'),localStorage.getItem('token') || '')
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'||file.type === 'image/gif'
      const isLt2M = file.size / 1024 / 1024 < this.fileSize
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG或者PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error(`上传图片大小不能超过 ${this.fileSize}MB!`)
      }
      return isJPG && isLt2M
    },
    handleDelete() {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.imageUrl = ''
        this.$emit('handleDelete')
      })
    },
    seeBigImg(url) {
      this.showViewer = true
      console.log(url);
      this.imgList.push(url)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
@import "src/assets/scss/element/theme-color";
.el-img-upload{
  .el-upload__tip{
    color: $--color-danger;
  }
}
</style>

<style lang="scss">
.el-img-upload{
  .el-upload {
    width: 120px;
    height: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-plus{
      font-size: 30px;
      color: #999999;
    }
    .el-icon-circle-close{
      position: absolute;
      color: #999999;
      top: 2px;
      right: 2px;
      z-index: 100;
      font-size: 15px;
      opacity: 0.5;
      transition: opacity 0.2s;
      &:hover{
        opacity: 0.8;
        color: #ef032a;
      }
    }
  }
}
</style>
