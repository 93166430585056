import Layout from '@/layout/layout'

const caseFailureRouter = {
  path: '/caseFailure',
  component: Layout,
  redirect: 'noRedirect',
  name: '报价配置信息',
  children: [
    {
      path: 'homeAdvertising',
      component: () => import('@/views/caseFailure/homeAdvertising'),
      name: '首页广告管理',
      meta: { title: '首页广告管理', noCache: true }
    },
    {
      path: 'caseImg',
      component: () => import('@/views/caseFailure/caseImg'),
      name: '旧机示例图',
      meta: { title: '旧机示例图', noCache: true }
    },
    {
      path: 'failure',
      component: () => import('@/views/caseFailure/failure'),
      name: '快捷备注',
      meta: { title: '快捷备注', noCache: true }
    },
    {
      path: 'coreFailure',
      component: () => import('@/views/caseFailure/coreFailure'),
      name: '核心故障',
      meta: { title: '核心故障', noCache: true }
    },
    {
      path: 'valuationManagement',
      component: () => import('@/views/caseFailure/valuationManagement'),
      name: '预估价管理',
      meta: { title: '预估价管理', noCache: true }
    },
    {
      path:'newCoreFailure',
      component:()=>import("@/views/caseFailure/newCoreFailure.vue"),
      name:'核心故障(新)',
      meta:{title:"核心故障(新)",noCache: true}
    }
  ]
}

export default caseFailureRouter
