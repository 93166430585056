<template>
  <el-aside id="aside" class="aside" :width="`${isCollapse ? '64' : '210'}px`">
    <el-menu
      :collapse="isCollapse"
      :default-active="defaultActive"
      :default-openeds="defaultOpeneds"
      :unique-opened="true"
      background-color="#273655"
      text-color="#C2C6CD"
      active-text-color="#ffffff"
    >
      <div v-for="tab in routersList" :key="tab.path">
        <el-menu-item
          v-if="tab.type === 'menu-item'"
          :index="tab.path"
          @click="tab.method || routerLinks(tab.path)"
        >
          <!-- <img class="mr-10 menu_icon" :src="bingActiveIcon(tab.icon)"  v-if="$route.path == tab.path"  /> -->
          <img class="mr-10 menu_icon" :src="bingIcon(tab.icon)" alt="" srcset=""/>
          <span slot="title" style="font-size: 16px">{{ tab.name }}</span>
        </el-menu-item>

        <el-submenu v-if="tab.type === 'submenu'" :index="tab.path">
          <template slot="title">
            <img class="mr-10 menu_icon" :src="bingIcon(tab.icon)" alt="" srcset="" />
            <span v-show="!isCollapse" slot="title">{{ tab.name }}</span>
          </template>
          <div v-for="item in tab.children" :key="item.path">
            <el-menu-item
              v-if="!item.method"
              :index="item.path"
              @click="routerLinks(item.path)"
            >
              {{ item.name }}
            </el-menu-item>
            <!--使用三目运算不能正常触发method，改成if-->
            <el-menu-item v-else :index="item.path" @click="item.method">
              {{ item.name }}
            </el-menu-item>
          </div>
        </el-submenu>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Aside",
  data() {
    return {
      /*
       * type: 'menu-item'(单个菜单)、'submenu'(菜单组)
       * */
      // routersList: [
      //   {
      //     path: "/dashboard",
      //     type: "menu-item",
      //     icon: "el-icon-s-home",
      //     name: "首页",
      //   },
      //   {
      //     type: "submenu",
      //     path: "/merchant",
      //     icon: "el-icon-picture-outline-round",
      //     name: "第三方回收商",
      //     children: [
      //       {
      //         path: "/merchant/list",
      //         name: "商家列表",
      //       },
      //       {
      //         path: "/merchant/apply",
      //         name: "入驻申请",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/oldBrand",
      //     icon: "el-icon-picture-outline-round",
      //     name: "品牌&机型",
      //     children: [
      //       {
      //         path: "/oldBrand/Resident",
      //         name: "品牌管理",
      //       },
      //       {
      //         path: "/oldBrand/Model",
      //         name: "机型管理",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/classification",
      //     icon: "el-icon-picture-outline-round",
      //     name: "回收分类管理",
      //     children: [
      //       {
      //         path: "/classification/ClassList",
      //         name: "分类列表",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/caseFailure",
      //     icon: "el-icon-picture-outline-round",
      //     name: "报价配置信息",
      //     children: [
      //       {
      //         path: "/caseFailure/caseImg",
      //         name: "旧机示例图",
      //       },
      //       {
      //         path: "/caseFailure/failure",
      //         name: "快捷备注",
      //       },
      //       {
      //         path: "/caseFailure/coreFailure",
      //         name: "核心故障",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/RecallOrder",
      //     icon: "el-icon-picture-outline-round",
      //     name: "回收订单管理",
      //     children: [
      //       {
      //         path: "/RecallOrder/index",
      //         name: "订单列表",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/systemManage",
      //     icon: "el-icon-picture-outline-round",
      //     name: "系统管理",
      //     children: [
      //       {
      //         path: "/systemManage/RolePurview",
      //         name: "角色权限",
      //       },
      //       {
      //         path: "/systemManage/StaffManage",
      //         name: "职员管理",
      //       },
      //       {
      //         path: "/systemManage/Resources",
      //         name: "资源配置",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/Feedback",
      //     icon: "el-icon-picture-outline-round",
      //     name: "意见反馈",
      //     children: [
      //       {
      //         path: "/Feedback/index",
      //         name: "意见反馈",
      //       },
      //     ],
      //   },

      //   {
      //     type: "submenu",
      //     path: "/SystemOffer",
      //     icon: "el-icon-picture-outline-round",
      //     name: "系统报价回收",
      //     children: [
      //       {
      //         path: "/SystemOffer/list",
      //         name: "商家列表",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/StoreShop",
      //     icon: "el-icon-picture-outline-round",
      //     name: "门店商家",
      //     children: [
      //       {
      //         path: "/StoreShop/index",
      //         name: "商家列表",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/qrcode",
      //     icon: "el-icon-picture-outline-round",
      //     name: "二维码管理",
      //     children: [
      //       {
      //         path: "/qrcode/CreatDistribute",
      //         name: "二维码生成&分配",
      //       },
      //       {
      //         path: "/qrcode/MerchantsQrcode",
      //         name: "门店商家二维码",
      //       },
      //       {
      //         path: "/qrcode/QrcodeSearch",
      //         name: "二维码查询",
      //       },
      //       {
      //         path: "/qrcode/QrcodeDelivery",
      //         name: "二维码发货",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/payment",
      //     icon: "el-icon-picture-outline-round",
      //     name: "支付管理",
      //     children: [
      //       {
      //         path: "/payment/PaymentSet",
      //         name: "支付设置",
      //       },
      //       {
      //         path: "/payment/BillList",
      //         name: "充值订单",
      //       },
      //     ],
      //   },
      //   {
      //     type: "submenu",
      //     path: "/DemoVideo",
      //     icon: "el-icon-picture-outline-round",
      //     name: "演示视频",
      //     children: [
      //       {
      //         path: "/DemoVideo/list",
      //         name: "视频配置",
      //       },
      //       // {
      //       //   path: '/DemoVideo/add',
      //       //   name: '详情'
      //       // }
      //     ],
      //   },
      // ],
      routersList:[],
      defaultActive: this.activeTab,
      // 默认打开的tabs: ['/feature']
      defaultOpeneds: [],
    };
  },
  created() {
    this.getMenuList();
  },
  mounted() {
    const path = this.$route.path;
    console.log(path);
    if (path.indexOf("/feature/TabsView") === -1) {
      this.defaultActive = path;
    } else {
      this.defaultActive = "/feature/TabsView";
    }
  },
  computed: {
    isCollapse() {
      return this.$store.state.tagsView.isCollapse;
    },
    routerPath() {
      return this.$store.state.tagsView.routerPath
    },
  },
  watch: {
    routerPath(path){
      console.log(path);
      if (path.indexOf("/feature/TabsView") === -1) {
        this.defaultActive = path;
      } else {
        this.defaultActive = "/feature/TabsView";
      }
    }
  },
  methods: {
    bingIcon(icon) {
      icon = icon || "default";
      let reg = icon.indexOf('http')
      return reg>=0?icon:require(`@/assets/menu/${icon}.png`);
    },
    bingActiveIcon(icon) {
      icon = icon || "default";
      let reg = icon.indexOf('http')
      return reg>=0?icon:require(`@/assets/menu/${icon}_hover.png`);
    },
    routerLinks(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    getMenuList() {
      _api.getMenuList().then((res) => {
        this.$store.commit('tagsView/MENU_LIST', res.data)
        res.data.forEach(item => {
          item.name = item.resourceName
          item.path = item.resourceUrl
          // if(item.resourceName == '首页'){
          //   item.icon = 'default'
          // }else{
          //   item.icon = 'bjpzxx'
          // }
          if(item.children && item.children.length != 0){
            item.type = 'submenu'
            item.children.forEach(ele => {
              ele.name = ele.resourceName
              ele.path = ele.resourceUrl
            })
          }else{
            item.type = 'menu-item'
          }
        });
        console.log(res.data);
        this.routersList = res.data
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.aside {
  background: #273655;
  border-radius: 0px 20px 20px 0px;
  transition: width 0.3s;
}
.mr-10{
  margin-right: 10px;
}
.menu_icon{
  width: 16px;
  height: 16px;
}
</style>
