// 旧机模板
import Layout from "@/layout/layout.vue";

const oldMachine = {
    // 加载路径
    path:"/oldMachine",
    // 加载组件
    component:Layout,
    // 重定向路径
    redirect: 'noRedirect',
    // 路由明
    name:"旧机模板管理",
    children:[
        {
            path:"templateList",
            name:"旧机模板管理",
            component: ()=>import("@/views/oldMachine/templateList/index.vue"),
            meta: { title: '旧机模板管理', noCache: true }
        },
        {
            path:"templateEdit",
            name:"编辑商品模板",
            component: ()=>import("@/views/oldMachine/templateEdit/index.vue"),
            meta: { title: '编辑商品模板', noCache: true }
        }
    ]
}

export default oldMachine;