import Layout from '@/layout/layout'

const RecallOrderRouter = {
    path: '/RecallOrder',
    component: Layout,
    redirect: 'noRedirect',
    name: '回收订单管理',
    children: [
        {
            path: 'index',
            component: () => import('@/views/RecallOrder/index'),
            name: '订单列表',
            meta: {title: '订单列表', noCache: true}
        },
        {
            path: 'Details',
            component: () => import('@/views/RecallOrder/Details'),
            name: '订单详情',
            meta: {title: '订单详情', noCache: true}
        },
        {
            path: 'Details',
            component: () => import('@/views/RecallOrder/Details')
        },
        {
            path: "evaluate",
            component: () => import("@/views/RecallOrder/evaluate.vue"),
            name: "估价单",
            meta: {title: "估价单", noCache: true}
        },
        {
            path: "ImportOrder",
            component: () => import("@/views/RecallOrder/ImportOrder/index.vue"),
            name: "导入订单退单",
            meta: {title: "导入订单退单", noCache: true}
        },
        {
            path: 'failedBidorder',
            component: () => import('@/views/RecallOrder/failedBidorder/index'),
            name: '竞价失败订单',
            meta: {title: '竞价失败订单', noCache: true}
        },
        {
            path: 'rejuectOrder',
            component: () => import('@/views/RecallOrder/rejuectOrder/index'),
            name: '报价单',
            meta: {title: '报价单', noCache: true}
        },
    ]
}

export default RecallOrderRouter
