import Layout from '@/layout/layout'

const oldBrandRouter = {
  path: '/oldBrand',
  component: Layout,
  redirect: 'noRedirect',
  name: '品牌&机型',
  children: [
    {
      path: 'add',
      component: () => import('@/views/oldBrand/add/index'),
      name: '添加品牌',
      meta: { title: '添加品牌', noCache: true }
    },
    {
      path: 'Model',
      component: () => import('@/views/oldBrand/Model/index'),
      name: '机型管理',
      meta: { title: '机型管理', noCache: true }
    },
    {
      path: 'Resident',
      component: () => import('@/views/oldBrand/Resident/index'),
      name: '品牌管理',
      meta: { title: '品牌管理', noCache: true }
    },
    {
      path: 'OutModel',
      component: () => import('@/views/oldBrand/OutModel/index'),
      name: '外部机型管理',
      meta: { title: '外部机型管理', noCache: true }
    },
  ]
}

export default oldBrandRouter
