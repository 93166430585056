import Layout from '@/layout/layout'

const onlineRecyclingRouter = {
    path: '/onlineRecycling',
    component: Layout,
    redirect: 'noRedirect',
    name: '线上回收',
    children: [
        {
            path: 'merchant',
            component: () => import('@/views/onlineRecycling/merchant/index'),
            name: '线上回收商户',
            meta: { title: '线上回收商户', noCache: true }
        },
        {
            path: 'config',
            component: () => import('@/views/onlineRecycling/config/index'),
            name: '问答配置',
            meta: { title: '问答配置', noCache: true }
        },
        {
            path: 'comments',
            component: () => import('@/views/onlineRecycling/comments/index'),
            name: '评论设置',
            meta: { title: '评论设置', noCache: true }
        },
        {
            path: 'advantage',
            component: () => import('@/views/onlineRecycling/advantage/index'),
            name: '到店优势配置',
            meta: { title: '到店优势配置', noCache: true }
        },
        {
            path: 'merchants',
            component: () => import('@/views/onlineRecycling/merchant/merchants.vue'),
            name: '添加商户',
            meta: { title: '添加商户', noCache: true }
        }
    ]
}

export default onlineRecyclingRouter
