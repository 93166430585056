<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true//控制视图是否显示的变量
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true;//再打开
      })
    }
  }
}
</script>

<style lang="scss">
@import "./assets/scss/app";

#app {
  width: 100%;
  min-height: 100%;
  background-color: #f5f6fa;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*解决element表格对不齐*/
.el-table th {
  display: table-cell !important;
}

.el-tooltip__popper {
  max-width: 900px;
}

.lv_fc {
  color: #0981FF;
}

.lv_fc_red{
  color: #FF0000;
}

.lv_fc_line {
  color: #0981FF;
  text-decoration: underline;
  cursor: pointer;
}

.lv_f_line {
  text-decoration: underline;
}

//溢出隐藏单
.lv_ycyc1{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

//2行
.lv_ycyc2{
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}
</style>
