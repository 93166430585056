import Layout from '@/layout/layout'

const transferManagementRouter = {
    path: '/transferManagement',
    component: Layout,
    redirect: 'noRedirect',
    name: '平台分转管理',
    children: [
        {
            path: 'subdistriButor',
            component: () => import('@/views/transferManagement/subdistriButor/index'),
            name: '分转商家管理',
            meta: { title: '分转商家管理', noCache: true }
        },
        {
            path: 'spunGoods',
            component: () => import('@/views/transferManagement/spunGoods/index'),
            name: '旧机发货跟踪',
            meta: { title: '旧机发货跟踪', noCache: true }
        },
        {
            path: 'expressOrder',
            component: () => import('@/views/transferManagement/expressOrder/index'),
            name: '快递下单',
            meta: { title: '快递下单', noCache: true }
        },
        {
            path: 'abnormalReminder',
            component: () => import('@/views/transferManagement/abnormalReminder/index'),
            name: '异常催单管理',
            meta: { title: '异常催单管理', noCache: true }
        },
        {
            path: 'merchatStockData',
            component: () => import('@/views/transferManagement/merchatStockData/index'),
            name: '回收商库存数据',
            meta: { title: '回收商库存数据', noCache: true }
        },
        {
            path: 'receiptDoc',
            component: () => import('@/views/transferManagement/receiptDoc/index'),
            name: '入库单据',
            meta: { title: '入库单据', noCache: true }
        },
    ]
}

export default transferManagementRouter
