import Layout from '@/layout/layout'

const creditManagementRouter = {
  path: '/creditManagement',
  component: Layout,
  redirect: 'noRedirect',
  name: '授信管理',
  children: [
    {
      path: 'contractManagement',
      component: () => import('@/views/creditManagement/contractManagement/index'),
      name: '合同管理',
      meta: { title: '合同管理', noCache: true }
    },
    {
      path: 'creditMerchantmanagement',
      component: () => import('@/views/creditManagement/creditMerchantmanagement/index'),
      name: '授信商家管理',
      meta: { title: '授信商家管理', noCache: true }
    },
    {
      path: 'loanStatistics',
      component: () => import('@/views/creditManagement/loanStatistics/index'),
      name: '借款统计',
      meta: { title: '借款统计', noCache: true }
    },
    {
      path: 'loanOrder',
      component: () => import('@/views/creditManagement/loanOrder/index'),
      name: '借款订单',
      meta: { title: '借款订单', noCache: true }
    },
    {
      path: 'loanStatement',
      component: () => import('@/views/creditManagement/loanStatement/index'),
      name: '借款账单',
      meta: { title: '借款账单', noCache: true }
    }
  ]
}

export default creditManagementRouter