import Layout from '@/layout/layout'

const classificationRouter = {
    path: '/payment',
    component: Layout,
    redirect: 'noRedirect',
    name: '支付管理',
    children: [
        {
            path: 'PaymentSet',
            component: () => import('@/views/payment/PaymentSet/index'),
            name: '支付设置',
            meta: { title: '支付设置', noCache: true }
        },
        {
            path: 'BillList',
            component: () => import('@/views/payment/BillList/index'),
            name: '收货次数账单',
            meta: { title: '收货次数账单', noCache: true }
        },
        {
            path: 'PayApply',
            component: () => import('@/views/payment/PayApply/index'),
            name: '线上支付申请',
            meta: { title: '线上支付申请', noCache: true }
        },
        {
            path: 'PayBill',
            component: () => import('@/views/payment/PaybBill/index'),
            name: '支付账单',
            meta: { title: '支付账单', noCache: true }
        },
        {
            path: 'PlatformBilling',
            component: () => import('@/views/payment/PlatformBilling/index'),
            name: '平台支付账单',
            meta: { title: '平台支付账单', noCache: true }
        },
        {
            path: 'keepAccounts',
            component: () => import('@/views/payment/PlatformBilling/keepAccounts'),
            name: '上账',
            meta: { title: '上账', noCache: true }
        },
        {
            path: 'PaybAccount',
            component: () => import('@/views/payment/PaybAccount/index'),
            name: '回收商账务',
            meta: { title: '回收商账务', noCache: true }
        },
        {
            path: 'Refund',
            component: () => import('@/views/payment/Refund/index'),
            name: '退款申请',
            meta: { title: '退款申请', noCache: true }
        },
        {
            path: 'BalanceWarn',
            component: () => import('@/views/payment/BalanceWarn/index'),
            name: '余额提醒',
            meta: { title: '余额提醒', noCache: true }
        },
        {
            path: 'WechatAccount',
            component: () => import('@/views/payment/WechatAccount/index'),
            name: '微信账户交易',
            meta: { title: '微信账户交易', noCache: true }
        },
        {
            path: 'PaySet',
            component: () => import('@/views/payment/PayApply/paySet/index'),
            name: '支付设置',
            meta: { title: '支付设置', noCache: true }
        },
        {
            path: 'setAccount',
            component: () => import('@/views/payment/PaybAccount/setAccount'),
            name: '设置账目',
            meta: { title: '设置账目', noCache: true }
        },
        {
            path: 'storeHome',
            component: () => import('@/views/storeHome/home'),
            name: '门店首页',
            meta: { title: '门店首页', noCache: true}
        },
        {
            path: 'storeRatio',
            component: () => import('@/views/dataStatistics/index'),
            name: '环比数据',
            meta: { title: '环比数据', noCache: true}
        },
        {
            path: 'Catchupstatistics',
            component: () => import('@/views/Makeuporderstatistics/index'),
            name: '补拍统计',
            meta: { title: '补拍统计', noCache: true}
        },
        {
            path: 'redenvelope',
            component: () => import('@/views/payment/RedEnvelope/redenvelopeHome'),
            name: '门店红包统计',
            meta: { title: '门店红包统计', noCache: true }
        },
        {
            path: 'BankCard',
            component: () => import('@/views/payment/BankCard/index'),
            name: '银行卡提现',
            meta: { title: '银行卡提现', noCache: true }
        },
        {
            path: 'etcOrderNum',
            component: () => import('@/views/payment/BillList/etcOrderNum.vue'),
            name: '充值收货次数',
            meta: { title: '充值收货次数', noCache: true }
        },
        {
            path: 'TransactionStatement',
            component: () => import('@/views/payment/TransactionStatement/index.vue'),
            name: '渠道交易账单',
            meta: { title: '渠道交易账单', noCache: true }
        },
        {
            path: 'PlatformAccount',
            component: () => import('@/views/payment/PlatformAccount/index.vue'),
            name: '渠道交易下账',
            meta: { title: '渠道交易下账', noCache: true }
        },
        {
            path: 'storeAccount',
            component: () => import('@/views/payment/storeAccount/index.vue'),
            name: '门店商下账',
            meta: { title: '门店商下账', noCache: true }
        },
        {
            path: 'ReceivePayment',
            component: () => import('@/views/payment/ReceivePayment/index.vue'),
            name: '收货打款',
            meta: { title: '收货打款', noCache: true }
        },
        {
            path: 'setRemit',
            component: () => import('@/views/payment/ReceivePayment/setRemit.vue'),
            name: '添加收货打款',
            meta: { title: '添加收货打款', noCache: true }
        },
    ]
}

export default classificationRouter
