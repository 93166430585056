<template>
  <div id="layout" class="layout">
    <div class="header-info">
      <div class="top-left">{{ headerTitle }}</div>
      <div class="top-right">
        <div class="expire_left" @click="jumpTocertificate">
          <span v-if="stateStatisticData.expireNum > 0 && stateStatisticData.willExpireNum > 0">当前有<span
              style="color: red;">{{ stateStatisticData.willExpireNum }}个</span>证书即将到期，<span
              style="color: red;">{{ stateStatisticData.expireNum }}个</span>证书已过期，请及时处理！</span>
          <span v-if="stateStatisticData.expireNum == 0 && stateStatisticData.willExpireNum > 0">当前有<span
              style="color: red;">{{ stateStatisticData.willExpireNum }}个</span>证书即将到期，请及时处理！</span>
          <span v-if="stateStatisticData.expireNum > 0 && stateStatisticData.willExpireNum == 0">当前有<span
              style="color: red;">{{ stateStatisticData.expireNum }}个</span>证书已过期，请及时处理！</span>
          <span v-if="stateStatisticData.expireNum == 0 && stateStatisticData.willExpireNum == 0">暂无即将到期证书</span>
        </div>
        <div style=" margin-right: 100px;">
          <span class="bance3203">3023余额：￥{{ num }}</span>
          <el-button size="small" @click="refreshPrice" type="warning" round>刷新</el-button>
        </div>
        <div class="bg-white">
          <img :src="userImg" alt="" />
        </div>
        <div class="bg-user">
          <span>{{ userName }}</span>
          <!-- <span class="button" style="margin-left: 4px;overflow: hidden" @click="isUser=!isUser">
            <img
              src="@/assets/images/icon_shang.png"
              style="transition: all 0.3s;"
              :style="{transform: `rotate(${isUser ? '-180deg' : 0})`}"
            />
          </span>
          <div class="bar-console" v-show="false">
            <span @click="closeSwitch">切换</span>
            <span @click="closeSwitch">切换</span>
          </div> -->
        </div>
        <div class="bg-white" @click="closeLogin">
          <img src="@/assets/images/icon_kg.png" alt />
        </div>
      </div>
    </div>
    <el-container style="height: calc(100vh - 50px)">
      <Aside />

      <el-container>
        <el-header>
          <!--页面标签栏-->
          <Breadcrumb />
        </el-header>

        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { Aside, Breadcrumb } from "./components";
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "Layout",
  components: { Aside, Breadcrumb },
  data() {
    return {
      num: '--',
      userImg: "",
      isUser: false,
      headerTitle: "济南繁星回收后台管理系统",
      userName: "",
      stateStatisticData: {
        "expireNum": 0,
        "willExpireNum": 0
      },
    };
  },
  created() {
    this.userImg = localStorage.getItem("userImg");
    this.userName = localStorage.getItem("userName");
    if (localStorage.getItem('remainingsum')) {
      this.num = localStorage.getItem('remainingsum') || 0
    }
    this.setPurviewButton();
    this.getstateStatistics();
  },
  methods: {
    ...mapActions('tagsView', ['setPurviewButton']),
    //调3023余额接口
    refreshPrice() {
      _api.userbalance().then((res) => {
        console.log(res);
        localStorage.setItem('remainingsum', res.data.data.balance)
        this.num = res.data.data ? res.data.data.balance : 0
      });
    },
    //证书数量统计
    getstateStatistics() {
      _api.stateStatistics().then(res => {
        this.stateStatisticData = res.data
      })
    },
    jumpTocertificate() {
      console.log(this.$router);
      if (this.$router.history.current.fullPath === "/systemManage/certificateManagement") {
        return false
      }
      this.$router.push({
        path: "/systemManage/certificateManagement"
      })
    },
    closeLogin() {
      this.$confirm("你是否要退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出登录成功!",
          });
          _api.logout({ token: localStorage.getItem("token") }).then((res) => {
            if (res.code === 1) {
              localStorage.clear();
              this.$router.push("/login");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    closeSwitch() { },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.layout {

  aside,
  main {

    /*滚动条样式设置*/
    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 0.5em;
      -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    }

    /*滑块*/
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 0.5em;
      -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    }

    /*滑块轨道*/
    &::-webkit-scrollbar-track {
      border-radius: 0.5em;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }
  }

  .el-header {
    width: 100%;
    font-size: 12px;
    background-color: #f5f6fa;
    color: #333;
    //line-height: 60px;
    height: auto !important;
    box-shadow: 0px 1px 3px 0px rgba(208, 208, 208, 0.8);
  }

  .el-aside {
    color: #333;
  }

  .header-info {
    height: 50px;
    background: #0981ff;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-left {
      width: 250px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      font-family: MF LangQian (Noncommercial);
      font-weight: 400;
      color: #ffffff;
      margin-left: 20px;
    }

    .top-right {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      margin-right: 20px;

      .bg-white {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          border-radius: 50%;
        }
      }

      .bg-user {
        display: flex;
        width: 63px;
        height: 20px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #ffffff;
        margin: 0 20px 0 10px;

        .bar-console {
          z-index: 9999;
          position: absolute;
          right: 60px;
          top: 50px;
          width: 100px;
          height: 60px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 4px 10px 0px rgba(92, 92, 92, 0.15);
          opacity: 0.9;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          span {
            cursor: pointer;
            width: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);

            &:hover {
              background-color: rgba(#d4d9f8, 0.5);
            }
          }
        }

        .button {
          width: 12px;
          height: 15px;
          border-radius: 3px;
          cursor: pointer;

          >img {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.bance3203 {
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  color: red;
  font-weight: 700;
  margin-right: 20px;
}

.expire_left {
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  color: #666666;
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
}</style>
