import Layout from '@/layout/layout'

const FeedbackRouter = {
  path: '/Feedback',
  component: Layout,
  redirect: 'noRedirect',
  name: '意见反馈',
  children: [
    {
      path: 'index',
      component: () => import('@/views/Feedback/index'),
      name: '反馈列表',
      meta: { title: '反馈列表', noCache: true }
    },
  ]
}

export default FeedbackRouter
