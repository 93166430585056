import Layout from '@/layout/layout'

const DemoVideoRouter = {
  path: '/DemoVideo',
  component: Layout,
  redirect: 'noRedirect',
  name: '系统管理',
  children: [
    {
      path:'list',
      component: () => import('@/views/DemoVideo/list/index'),
      name: '视频配置',
      meta: { title: '视频配置', noCache: true }
    },
    {
      path:'add',
      component: () => import('@/views/DemoVideo/add/VideoAdd'),
      name: '详情',
      meta: { title: '详情', noCache: true }
    }
  ]
}

export default DemoVideoRouter
