import Layout from '@/layout/layout'

const StoreShopRouter = {
    path: '/StoreShop',
    component: Layout,
    redirect: 'noRedirect',
    name: '门店商家',
    children: [
        {
            path: 'index',
            component: () => import('@/views/StoreShop/index'),
            name: '商家列表',
            meta: {title: '商家列表', noCache: true}
        },
        {
            path: 'Addstore',
            component: () => import('@/views/StoreShop/Addstore'),
            name: '添加商家',
            meta: {title: '添加商家', noCache: true}
        },
        {
            path: "priceMarkup",
            component: () => import("@/views/StoreShop/PriceMarkup.vue"),
            name: "平台加价设置",
            meta: {
                title: '平台加价设置',
                noCache: true
            }
        },
        {
            path: 'editStore',
            component: () => import('@/views/StoreShop/Addstore'),
            name: '编辑商家',
            meta: {title: '编辑商家', noCache: true}
        },
    ]
}

export default StoreShopRouter
